'use client';

import { useSearchParams } from 'next/navigation';
import { forwardRef, ForwardedRef, useEffect } from 'react';

import { useOrderQuery } from '~/hooks/useGetOrderQuery';

import { OrderFound } from './OrderFound';
import { OrderNotFound } from './OrderNotFound';

export const OrderDetails = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const search = useSearchParams();

  const orderNumber = search.get('orderNumber') as string;

  const { data: order, refetch, error, isLoading } = useOrderQuery({ orderNumber, enabled: Boolean(orderNumber) });

  useEffect(() => {
    if (orderNumber) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNumber]);

  if (!orderNumber || error || isLoading) {
    return <OrderNotFound orderNumber={orderNumber} error={error} isLoading={isLoading} {...props} ref={ref} />;
  }

  if (order) {
    return <OrderFound {...props} ref={ref} order={order} />;
  }

  return null;
});

OrderDetails.displayName = 'OrderDetails';
