import { Text, Box, Flex, Button, Alert, AlertIcon, AlertDescription, Link } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useRouter } from 'next/navigation';
import { forwardRef, ForwardedRef, useEffect } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { InferType, object, string } from 'yup';

import MessageQuestionCircle from '~/assets/icons/message-question-circle.svg';
import { Arrow } from '~/components/Arrow';
import { InputControl } from '~/components/form/input';
import { sleep } from '~/helpers/sleep';

const schema = object().shape({
  orderNumber: string().required('Order number is required'),
});

type FormData = InferType<typeof schema>;

const cardStyles = {
  padding: { base: '1rem', md: '1.5rem' },
  borderRadius: '0.5rem',
  border: '1.5px solid black',
};

type OrderNotFoundProps = {
  error: AxiosError | null;
  isLoading: boolean;
  orderNumber?: string;
};

export const OrderNotFound = forwardRef((props: OrderNotFoundProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { error, isLoading, orderNumber, ...rest } = props;
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      orderNumber: '',
    } as DefaultValues<FormData>,
  });

  const router = useRouter();

  useEffect(() => {
    if (orderNumber) {
      reset({ orderNumber } as DefaultValues<FormData>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNumber]);

  const onSubmit = async (data: FormData) => {
    await sleep(500);

    router.push(`/order?${new URLSearchParams({ orderNumber: data.orderNumber }).toString()}`);
  };

  return (
    <>
      <Text fontFamily='heading' fontSize='36px' marginTop='3rem' marginBottom='1rem'>
        Let&apos;s find your order!
      </Text>
      <Box {...rest} ref={ref}>
        <Box {...cardStyles} as='form' onSubmit={handleSubmit(onSubmit)}>
          <InputControl
            name='orderNumber'
            control={control}
            inputProps={{ placeholder: 'Enter your order number' }}
            disabeld={Boolean(orderNumber) && !error}
            marginBottom='1rem'
          />
          {error ? (
            <Alert status='error' marginBottom='1rem' title='Error'>
              <AlertIcon />
              <AlertDescription>{error.message}</AlertDescription>
            </Alert>
          ) : null}
          <Flex gap='0.75rem' alignItems='center' marginBottom='1.5rem'>
            <Flex>
              <MessageQuestionCircle style={{ color: 'var(--chakra-colors-black50)' }} />
            </Flex>
            <Text fontSize='13px' color='black50'>
              Make sure you entered correct order number. In case you face any issues please contact our support{' '}
              <Link color='primary' display='inline'>
                hello@drinkharmonia.com
              </Link>
            </Text>
          </Flex>
          <Flex flexDir='column' gap='0.5rem' alignItems='center'>
            <Button type='submit' isLoading={isSubmitting || isLoading}>
              Continue <Arrow dir='right' fill='white' />
            </Button>
          </Flex>
        </Box>
      </Box>
    </>
  );
});

OrderNotFound.displayName = 'OrderNotFound';
