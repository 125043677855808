import { Button, Text, Grid, Box, Flex, Spinner } from '@chakra-ui/react';
import { Product } from '@menesko/models-harmonia';
import { generalUtils } from '@shared/common';
import Image from 'next/image';
import { useState } from 'react';

import CheckSVG from '~/assets/icons/check.svg';
import CheckcircleSVG from '~/assets/icons/checkcircle.svg';
import Coctail1Image from '~/assets/images/coctail_1.png';
import Coctail3Image from '~/assets/images/coctail_3.png';
import Coctail6Image from '~/assets/images/coctail_6.png';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';
import { useTracking } from '~/hooks/useTracking';
import { AppRoute } from '~/types';

export const PlanSelection = () => {
  const { updateLead } = useAppLead();
  const { mainProducts } = useProducts();
  const { plansSelect } = useTracking();
  const { pushNextRoute } = useAppRouter(AppRoute.SHIPPING);
  const [isSubscription, setIsSubscription] = useState(true);

  const handleSelection = (product: Product) => {
    updateLead({ selectedPlan: product });

    plansSelect({
      category: product.key,
      label: product.title,
    });

    pushNextRoute();
  };

  return (
    <Grid width='100%' gap='1rem' gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}>
      {(() => {
        const product = mainProducts[6][isSubscription ? 'sub' : 'main'];
        const price = product?.prices?.find((p) => p.currency === 'USD');
        const finalPrice = (price?.finalPrice || 27.99) / 6;
        const discountProcent = 100 - ((price?.finalPrice || 0) / (price?.price || 0)) * 100;

        return product ? (
          <Box
            position='relative'
            padding='1.5rem'
            borderRadius='1rem'
            border='1.5px solid'
            borderColor='green'
            bg='white'
            boxShadow={{ base: 'none', md: '0px 6px 14px 0px #3A271D14, 0px 25px 25px 0px #3A271D12' }}
          >
            <Flex
              flexDir={{ base: 'row', md: 'column-reverse' }}
              marginBottom='1rem'
              paddingBottom='1rem'
              borderBottom='1px solid #2222221a'
              gap='1rem'
            >
              <Box flex={1}>
                <Flex
                  position={{ base: 'static', md: 'absolute' }}
                  top='-12px'
                  left={0}
                  right={0}
                  marginBottom={{ base: '0.5rem', md: 0 }}
                  justifyContent={{ base: 'initial', md: 'center' }}
                >
                  <Text
                    display='inline-block'
                    color='white'
                    fontSize={{ base: '13px', md: '15px' }}
                    lineHeight='130%'
                    bg='green'
                    padding={{ base: '3px 9px', md: '4px 1rem' }}
                    borderRadius='0.5rem'
                  >
                    Best value
                  </Text>
                </Flex>
                <Text marginBottom='0.5rem'>
                  6 bottles /<br />
                  {isSubscription ? `6-Months supply` : 'For 6 months'}
                </Text>
                <Flex
                  alignItems='baseline'
                  gap='6px'
                  marginBottom='0.5rem'
                  paddingBottom='0.5rem'
                  borderBottom='1px solid #F0EDEB'
                >
                  <Text lineHeight='100%' fontFamily='heading' fontSize='32px'>
                    {generalUtils.formatPrice(finalPrice, 'USD')}
                  </Text>
                  <Text fontSize='12px' color='black40'>
                    per bottle
                  </Text>
                </Flex>
                <Flex flexDir='column' gap='0.25rem'>
                  <Flex alignItems='center' gap='0.5rem'>
                    <Flex>
                      <CheckSVG />
                    </Flex>
                    <Text fontSize='14px'>180 servings</Text>
                  </Flex>
                  <Flex alignItems='center' gap='0.5rem'>
                    <Flex>
                      <CheckSVG />
                    </Flex>
                    <Text fontSize='14px'>6 bottles {isSubscription ? `every 6 months` : 'will be delivered'}</Text>
                  </Flex>
                </Flex>
              </Box>
              <Flex
                flex={1}
                width='100%'
                position='relative'
                paddingBottom={{ base: '45%', md: '90%' }}
                alignItems='flex-start'
              >
                <Image src={Coctail6Image} fill priority placeholder='blur' alt='' objectFit='contain' />
              </Flex>
            </Flex>
            <Flex flexDir='column' gap='0.5rem' marginBottom='1rem'>
              <Flex alignItems='center' cursor='pointer' gap='0.5rem' onClick={() => setIsSubscription(false)}>
                {isSubscription ? (
                  <Flex width='1.5rem' height='1.5rem' border='1px solid #C8BDB6' borderRadius='2rem' />
                ) : (
                  <CheckcircleSVG />
                )}
                <Text fontSize='14px'>One-time purchase</Text>
              </Flex>
              <Flex alignItems='center' cursor='pointer' gap='0.5rem' onClick={() => setIsSubscription(true)}>
                {isSubscription ? (
                  <CheckcircleSVG />
                ) : (
                  <Flex width='1.5rem' height='1.5rem' border='1px solid #C8BDB6' borderRadius='2rem' />
                )}
                <Text fontSize='14px'>Subscribe & Save</Text>
                <Text fontSize='12px' color='white' padding='3px 9px' borderRadius='1rem' lineHeight='120%' bg='error'>
                  {`Save -${discountProcent.toFixed(0)}%`}
                </Text>
              </Flex>
            </Flex>
            <Button variant='black' fontWeight={600} onClick={() => handleSelection(product)}>
              {isSubscription ? `Subscribe now` : 'Order now'}
            </Button>
          </Box>
        ) : (
          <Flex justifyContent='center'>
            <Spinner />
          </Flex>
        );
      })()}
      {(() => {
        const product = mainProducts[3][isSubscription ? 'sub' : 'main'];
        const price = product?.prices?.find((p) => p.currency === 'USD');
        const finalPrice = (price?.finalPrice || 43.99) / 3;
        const discountProcent = 100 - ((price?.finalPrice || 0) / (price?.price || 0)) * 100;

        return product ? (
          <Box
            position='relative'
            padding='1.5rem'
            borderRadius='1rem'
            border='1.5px solid'
            borderColor='black10'
            bg='#F4DCC61A'
          >
            <Flex
              flexDir={{ base: 'row', md: 'column-reverse' }}
              marginBottom='1rem'
              paddingBottom='1rem'
              borderBottom='1px solid #2222221a'
              gap='1rem'
            >
              <Box flex={1}>
                <Flex
                  position={{ base: 'static', md: 'absolute' }}
                  top='-12px'
                  left={0}
                  right={0}
                  marginBottom={{ base: '0.5rem', md: 0 }}
                  justifyContent={{ base: 'initial', md: 'center' }}
                >
                  <Text
                    display='inline-block'
                    color='white'
                    fontSize={{ base: '13px', md: '15px' }}
                    lineHeight='130%'
                    bg='blue'
                    padding={{ base: '3px 9px', md: '4px 1rem' }}
                    borderRadius='0.5rem'
                  >
                    Most popular
                  </Text>
                </Flex>
                <Text marginBottom='0.5rem'>
                  3 bottles /<br />
                  {isSubscription ? `3-Months supply` : 'For 3 months'}
                </Text>
                <Flex
                  alignItems='baseline'
                  gap='6px'
                  marginBottom='0.5rem'
                  paddingBottom='0.5rem'
                  borderBottom='1px solid #F0EDEB'
                >
                  <Text lineHeight='100%' fontFamily='heading' fontSize='32px'>
                    {generalUtils.formatPrice(finalPrice, 'USD')}
                  </Text>
                  <Text fontSize='12px' color='black40'>
                    per bottle
                  </Text>
                </Flex>
                <Flex flexDir='column' gap='0.25rem'>
                  <Flex alignItems='center' gap='0.5rem'>
                    <Flex>
                      <CheckSVG />
                    </Flex>
                    <Text fontSize='14px'>90 servings</Text>
                  </Flex>
                  <Flex alignItems='center' gap='0.5rem'>
                    <Flex>
                      <CheckSVG />
                    </Flex>
                    <Text fontSize='14px'>3 bottles {isSubscription ? 'every 3 months' : 'will be delivered'}</Text>
                  </Flex>
                </Flex>
              </Box>
              <Flex
                flex={1}
                width='100%'
                position='relative'
                paddingBottom={{ base: '45%', md: '90%' }}
                alignItems='flex-start'
              >
                <Image src={Coctail3Image} fill priority placeholder='blur' alt='' objectFit='contain' />
              </Flex>
            </Flex>
            <Flex flexDir='column' gap='0.5rem' marginBottom='1rem'>
              <Flex alignItems='center' cursor='pointer' gap='0.5rem' onClick={() => setIsSubscription(false)}>
                {isSubscription ? (
                  <Flex width='1.5rem' height='1.5rem' border='1px solid #C8BDB6' borderRadius='2rem' />
                ) : (
                  <CheckcircleSVG />
                )}
                <Text fontSize='14px'>One-time purchase</Text>
              </Flex>
              <Flex alignItems='center' cursor='pointer' gap='0.5rem' onClick={() => setIsSubscription(true)}>
                {isSubscription ? (
                  <CheckcircleSVG />
                ) : (
                  <Flex width='1.5rem' height='1.5rem' border='1px solid #C8BDB6' borderRadius='2rem' />
                )}
                <Text fontSize='14px'>Subscribe & Save</Text>
                <Text fontSize='12px' color='white' padding='3px 9px' borderRadius='1rem' lineHeight='120%' bg='error'>
                  {`Save -${discountProcent.toFixed(0)}%`}
                </Text>
              </Flex>
            </Flex>
            <Button variant='black' fontWeight={600} onClick={() => handleSelection(product)}>
              {isSubscription ? `Subscribe now` : 'Order now'}
            </Button>
          </Box>
        ) : (
          <Flex justifyContent='center'>
            <Spinner />
          </Flex>
        );
      })()}
      {(() => {
        const product = mainProducts[1][isSubscription ? 'sub' : 'main'];
        const price = product?.prices?.find((p) => p.currency === 'USD');
        const finalPrice = price?.finalPrice || 64.99;
        const discountProcent = 100 - ((price?.finalPrice || 0) / (price?.price || 0)) * 100;

        return product ? (
          <Box
            position='relative'
            padding='1.5rem'
            borderRadius='1rem'
            border='1.5px solid'
            borderColor='black10'
            bg='#F4DCC61A'
          >
            <Flex
              flexDir={{ base: 'row', md: 'column-reverse' }}
              marginBottom='1rem'
              paddingBottom='1rem'
              borderBottom='1px solid #2222221a'
              gap='1rem'
            >
              <Box flex={1}>
                <Text marginBottom='0.5rem'>
                  1 bottle /<br />
                  {isSubscription ? `1-Month supply` : 'For 1 month'}
                </Text>
                <Flex
                  alignItems='baseline'
                  gap='6px'
                  marginBottom='0.5rem'
                  paddingBottom='0.5rem'
                  borderBottom='1px solid #F0EDEB'
                >
                  <Text lineHeight='100%' fontFamily='heading' fontSize='32px'>
                    {generalUtils.formatPrice(finalPrice, 'USD')}
                  </Text>
                  <Text fontSize='12px' color='black40'>
                    per bottle
                  </Text>
                </Flex>
                <Flex flexDir='column' gap='0.25rem'>
                  <Flex alignItems='center' gap='0.5rem'>
                    <Flex>
                      <CheckSVG />
                    </Flex>
                    <Text fontSize='14px'>30 servings</Text>
                  </Flex>
                  <Flex alignItems='center' gap='0.5rem'>
                    <Flex>
                      <CheckSVG />
                    </Flex>
                    <Text fontSize='14px'>1 bottle {isSubscription ? `every month` : 'will be delivered'}</Text>
                  </Flex>
                </Flex>
              </Box>
              <Flex
                flex={1}
                width='100%'
                position='relative'
                paddingBottom={{ base: '45%', md: '90%' }}
                alignItems='flex-start'
              >
                <Image src={Coctail1Image} fill priority placeholder='blur' alt='' objectFit='contain' />
              </Flex>
            </Flex>
            <Flex flexDir='column' gap='0.5rem' marginBottom='1rem'>
              <Flex alignItems='center' cursor='pointer' gap='0.5rem' onClick={() => setIsSubscription(false)}>
                {isSubscription ? (
                  <Flex width='1.5rem' height='1.5rem' border='1px solid #C8BDB6' borderRadius='2rem' />
                ) : (
                  <CheckcircleSVG />
                )}
                <Text fontSize='14px'>One-time purchase</Text>
              </Flex>
              <Flex alignItems='center' cursor='pointer' gap='0.5rem' onClick={() => setIsSubscription(true)}>
                {isSubscription ? (
                  <CheckcircleSVG />
                ) : (
                  <Flex width='1.5rem' height='1.5rem' border='1px solid #C8BDB6' borderRadius='2rem' />
                )}
                <Text fontSize='14px'>Subscribe & Save</Text>
                <Text fontSize='12px' color='white' padding='3px 9px' borderRadius='1rem' lineHeight='120%' bg='error'>
                  {`Save -${discountProcent.toFixed(0)}%`}
                </Text>
              </Flex>
            </Flex>
            <Button variant='black' fontWeight={600} onClick={() => handleSelection(product)}>
              {isSubscription ? `Subscribe now` : 'Order now'}
            </Button>
          </Box>
        ) : (
          <Flex justifyContent='center'>
            <Spinner />
          </Flex>
        );
      })()}
    </Grid>
  );
};
