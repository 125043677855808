import { QuizAnswers, Lead } from '@menesko/models-harmonia';
import { UserContext } from '@shared/common';
import { useContext } from 'react';

export const useAppLead = () => {
  const context = useContext(UserContext);
  const user = context.user as Lead;

  return {
    ...user,
    updateLead: (data: Partial<Lead>) =>
      context.setUser({
        ...user,
        ...data,
      }),
    updateQuizAnswers: (data: Partial<QuizAnswers>) =>
      context.setUser({ ...user, quizAnswers: { ...user.quizAnswers, ...data } }),
  };
};
