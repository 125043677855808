import { Product } from '@menesko/models-harmonia';
import { useQuery } from 'react-query';

import { APIClient, FunctionName, getAPIUrl } from '~/firebase-init';

export const useProductsQuery = () => {
  return useQuery<{ products: Product[] }>(FunctionName.PRODUCT, {
    queryFn: () => APIClient.get(getAPIUrl(FunctionName.PRODUCT)),
    staleTime: Infinity,
  });
};
