import { InputProps } from '@chakra-ui/react';
import { Select, ChakraStylesConfig } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import { BaseProps, FormControl } from '../form-control';

type Option = { label: string; value: string };

type SelectControlProps = {
  inputProps?: InputProps;
  options: Option[];
  disabled?: boolean;
} & BaseProps;

export const SelectControl = ({ inputProps, name, control, options, disabled, ...rest }: SelectControlProps) => {
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
    control,
  });

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  // prevent SSR Hydration missamtch
  if (!isClient) {
    return null;
  }

  const handleChange = (option: Option) => {
    field.onChange(option.value);
  };

  const value = field.value ? { label: field.value, value: field.value } : undefined;

  return (
    <FormControl name={name} control={control} {...rest}>
      <Select
        {...field}
        {...inputProps}
        chakraStyles={styles}
        onChange={handleChange}
        value={value}
        options={options}
        disabeld={isSubmitting || disabled}
      />
    </FormControl>
  );
};

const styles: ChakraStylesConfig = {
  menu: (provided) => ({
    ...provided,
    border: '1.5px solid var(--chakra-colors-black)',
    borderRadius: 'var(--chakra-radii-md)',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'gray.200' : 'white',
    color: 'var(--chakra-colors-black)',
    fontWeight: state.isSelected ? 'bold' : 'normal',
  }),
};
