type GetTrackingUrlProps = {
  carrier: string;
  trackingNumber: string;
};

export const getTrackingUrl = ({ carrier, trackingNumber }: GetTrackingUrlProps) => {
  const carriers: Record<string, string> = {
    USPS: `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`,
    UPS: `https://www.ups.com/track?track=yes&trackNum=${trackingNumber}=en_US`,
    FedEx: `https://www.fedex.com/fedextrack/?tracknumbers=${trackingNumber}`,
  };

  const defaultUrl = `https://17track.net/en?nums=${trackingNumber}`;

  return carriers[carrier] || defaultUrl;
};
