import { CustomFields } from '@menesko/models-harmonia';
import cookie from 'js-cookie';

export const useCookies = (): CustomFields => {
  return {
    fbclid: cookie.get('_fbc'),
    fbpixel: cookie.get('_fbp'),
    ip: cookie.get('x-ip'),
    city: cookie.get('x-city'),
    countryCode: cookie.get('x-country-code'),
    country: cookie.get('x-country'),
    region: cookie.get('x-region'),
    userAgent: typeof navigator === 'undefined' ? undefined : navigator.userAgent,
  };
};
