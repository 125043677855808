import { Box, Button, CloseButton, Flex, Text, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { shippingDetailsSchema, ShippingDetails } from '@menesko/models-harmonia';
import { AppRoute } from '@shared/common';
import { useForm } from 'react-hook-form';

import { states } from '~/helpers/constants';
import { sleep } from '~/helpers/sleep';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useLeadCreateMutation } from '~/hooks/useLeadCreateMutation';
import { useLeadUpdateMutation } from '~/hooks/useLeadUpdateMutation';

import { InputControl } from '../form/input';
import { InputPhoneControl } from '../form/input-phone';
import { SelectControl } from '../form/select';


const statesOptions = states.map((state) => ({ label: state, value: state }));

export const ShippingForm = () => {
  const { pushNextRoute, back } = useAppRouter(AppRoute.CHECKOUT);
  const { id: leadId, email, shippingDetails } = useAppLead();
  const {
    mutate: update,
    isLoading: isUpdateLoading,
    error: errorUpdate,
  } = useLeadUpdateMutation({
    onSuccess: () => {
      pushNextRoute();
    },
  });

  const {
    mutate: create,
    isLoading: isCreateLoading,
    error: errorCreate,
  } = useLeadCreateMutation({
    onSuccess: () => {
      pushNextRoute();
    },
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<ShippingDetails>({
    resolver: yupResolver(shippingDetailsSchema),
    defaultValues: {
      firstName: shippingDetails?.firstName,
      lastName: shippingDetails?.lastName,
      apartment: shippingDetails?.apartment,
      city: shippingDetails?.city,
      zip: shippingDetails?.zip,
      email: shippingDetails?.email || email,
      phone: shippingDetails?.phone,
      country: 'United States (FREE shipping)',
      addressLine1: shippingDetails?.addressLine1,
      addressLine2: shippingDetails?.addressLine2,
      state: shippingDetails?.state,
    },
  });

  const onSubmit = async (data: ShippingDetails) => {
    await sleep(500);

    const shippingDetails = {
      ...data,
      // Shipstation requires country to be in 2-character ISO 3116-1 format
      country: 'US',
    };

    if (leadId) {
      await update({
        id: leadId,
        shippingDetails,
      });
    } else {
      await create({
        email: data.email,
        shippingDetails,
      });
    }
  };

  const isLoading = isUpdateLoading || isCreateLoading;
  const error = errorUpdate || errorCreate;

  return (
    <Box width='100%'>
      <Flex justifyContent='right' marginBottom='1.5rem'>
        <CloseButton onClick={back} color='black' border='none' />
      </Flex>
      <Flex alignItems='center' marginBottom='0.75rem'>
        {/* <CheckCircleSVG fill={colors.primary} stroke={colors.primary} /> */}
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='primary' borderRadius='2rem' />
        <Box flex={1} height='1px' bg='black10' />
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='black10' borderRadius='2rem' />
        <Box flex={1} height='1px' bg='black10' />
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='black10' borderRadius='2rem' />
      </Flex>
      <Flex justifyContent='space-between' marginBottom='1.5rem'>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          SHIPPING
        </Text>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          PAYMENT
        </Text>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          RECEIPT
        </Text>
      </Flex>
      <Text
        textAlign='center'
        fontSize={{ base: '24px', md: '32px' }}
        fontFamily='heading'
        marginBottom={{ base: '1.5rem', md: '1.5rem' }}
      >
        Enter your shipping details
      </Text>
      <Box as='form' onSubmit={handleSubmit(onSubmit)} maxW='24rem' margin='auto'>
        <Flex flexDir='column' gap='0.75rem' marginBottom='1.5rem'>
          <Flex gap='0.75rem'>
            <InputControl name='firstName' inputProps={{ placeholder: 'First name' }} control={control} />
            <InputControl name='lastName' inputProps={{ placeholder: 'Last name' }} control={control} />
          </Flex>
          <InputControl name='addressLine1' inputProps={{ placeholder: 'Address Line 1' }} control={control} />
          <InputControl
            name='addressLine2'
            inputProps={{ placeholder: 'Address Line 2 (optional)' }}
            control={control}
          />
          <InputControl disabeld name='country' control={control} />
          <SelectControl name='state' inputProps={{ placeholder: 'State' }} control={control} options={statesOptions} />
          <InputControl name='city' inputProps={{ placeholder: 'Town / city' }} control={control} />
          <InputControl name='zip' inputProps={{ placeholder: 'Zip code' }} control={control} />
          <InputControl name='email' inputProps={{ placeholder: 'Email address' }} control={control} />
          <InputPhoneControl name='phone' inputProps={{ placeholder: 'Phone number' }} control={control} />
        </Flex>
        {error ? (
          <Alert status='error' marginBottom='1rem' title='Error'>
            <AlertIcon />
            <AlertDescription>{error.message}</AlertDescription>
          </Alert>
        ) : null}
        <Button type='submit' isLoading={isSubmitting || isLoading}>
          Continue
        </Button>
      </Box>
    </Box>
  );
};
