import { Box, Flex, Text } from '@chakra-ui/react';
import { QuizAnswers } from '@menesko/models-harmonia';
import { formulas } from '@shared/common';
import { format, addMonths, addDays } from 'date-fns';
import { ForwardedRef, forwardRef } from 'react';

import WeightLossChartSVG from '~/assets/icons/weight_loss_chart.svg';
import { useAppLead } from '~/hooks/useAppLead';

export const WeightLossChart = () => {
  const { isMetric, quizAnswers: quizAnswersBase } = useAppLead();

  const quizAnswers = quizAnswersBase as QuizAnswers;

  const weight = Number(quizAnswers.weight);
  const targetWeight = Number(quizAnswers.targetWeight);
  const units = isMetric ? 'kg' : 'lb';
  const timeInMonths = formulas.calcWeightLossTime(weight, targetWeight);

  return (
    <Box width='100%'>
      <Flex position='relative' marginBottom='0.375rem' borderBottom='1px solid rgba(0, 0, 0, 0.1)'>
        <Box position='absolute' padding='0.375rem 0.75rem' borderRadius='2rem' bg='black' top={1} left={-2}>
          <Text color='white' lineHeight='100%'>
            {`${isMetric ? formulas.lbsToKg(weight).toFixed(0) : weight} ${units}`}
          </Text>
        </Box>
        <Box position='absolute' padding='0.375rem 0.75rem' borderRadius='2rem' bg='black' bottom={8} right={-2}>
          <Text color='white' lineHeight='100%'>
            {`${isMetric ? formulas.lbsToKg(targetWeight).toFixed(0) : targetWeight} ${units}`}
          </Text>
        </Box>
        <WeightLossChartSVG width='100%' height='100%' viewBox='0 0 295 170' />
      </Flex>
      <Flex justifyContent='space-between' alignItems='center'>
        <Text color='black60' fontSize='12px'>
          Today
        </Text>
        <Text color='black60' fontSize='12px'>
          {`Day ${timeInMonths * 28}+`}
        </Text>
      </Flex>
      <Flex justifyContent='space-between' alignItems='center'>
        <Text fontWeight={500}>{format(new Date(), 'MMMM yyyy')}</Text>
        <Text fontWeight={500}>{format(addMonths(new Date(), timeInMonths), 'MMMM yyyy')}</Text>
      </Flex>
    </Box>
  );
};

export const WeightLossEstimationHeadline = forwardRef((props, ref: ForwardedRef<HTMLParagraphElement>) => {
  const { isMetric } = useAppLead();
  const units = isMetric ? 'kg' : 'lb';

  return (
    <Text {...props} ref={ref}>
      {`You can lose ${isMetric ? 1.5 : 3} ${units} by ${format(addDays(new Date(), 14), 'd MMMM')}`}
    </Text>
  );
});

WeightLossEstimationHeadline.displayName = 'WeightLossEstimationHeadline';

export const WeightLossProofDescription = forwardRef((props, ref: ForwardedRef<HTMLParagraphElement>) => {
  const { isMetric, quizAnswers: quizAnswersBase } = useAppLead();

  const quizAnswers = quizAnswersBase as QuizAnswers;

  const weight = Number(quizAnswers.weight);
  const units = isMetric ? 'kg' : 'lb';

  return (
    <Text {...props} ref={ref}>
      Increased cortisol levels typically influence metabolism and activate the body&apos;s fight-or-flight response.
      This can lead to heightened cravings, often without an obvious cause.
      <br />
      <br />
      One of the primary benefits of regulating cortisol levels, particularly for weight loss, is the reduction in
      late-night snacking. Properly balanced cortisol can help mitigate these cravings, contributing to more effective
      weight management and overall health benefits.
      <br />
      <br />
      If you start drinking Harmonia, we estimate that you will be able to lose 3 lb within the first 2 weeks. We have a
      database of more than 124,000 people, and we matched 9,247 similar to you (<b>{quizAnswers.gender}</b>,{' '}
      <b>{quizAnswers.age} years old</b>,{' '}
      <b>
        {Number(isMetric ? formulas.lbsToKg(weight) : weight).toFixed(0)} {units} starting weight
      </b>
      ).
    </Text>
  );
});

WeightLossProofDescription.displayName = 'WeightLossProofDescription';
