import { Order } from '@menesko/models-harmonia';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { APIClient, FunctionName, getAPIUrl } from '~/firebase-init';
import { sleep } from '~/helpers/sleep';


export const useOrderQuery = ({ orderNumber, enabled }: { orderNumber: string; enabled: boolean }) => {
  return useQuery<Order, AxiosError>([FunctionName.ORDER, orderNumber], {
    queryFn: async () => {
      await sleep(1000);

      return await APIClient.get(`${getAPIUrl(FunctionName.ORDER)}/${orderNumber}`);
    },
    staleTime: Infinity,
    enabled,
    retry: false,
  });
};
