import { Flex, StyleProps, Text } from '@chakra-ui/react';
import { Order } from '@menesko/models-harmonia';
import { generalUtils } from '@shared/common';
import { ForwardedRef, forwardRef } from 'react';

import { useCheckout } from '~/hooks/useCheckout';
import { useProducts } from '~/hooks/useProducts';

type OrderSummaryProps = {
  order?: Order;
  padding?: StyleProps['padding'];
  borderRadius?: StyleProps['borderRadius'];
  border?: StyleProps['border'];
  marginBottom?: StyleProps['marginBottom'];
};

export const OrderSummary = forwardRef((props: OrderSummaryProps, ref: ForwardedRef<HTMLDivElement>) => {
  const checkout = useCheckout();
  const { findProductById } = useProducts();

  const { order, ...rest } = props;

  const selectedPlan = order ? findProductById(order.items[0].productId) : checkout.selectedPlan;
  const currency = checkout.currency;
  const prices = selectedPlan?.prices?.find((price) => price.currency === checkout.currency);
  const price = prices?.price || 0;
  const finalPrice = prices?.finalPrice || 0;

  return (
    <Flex gap='0.25rem' flexDir='column' {...rest} ref={ref}>
      <Flex justifyContent='space-between' color='black90'>
        <Text>Harmonia {selectedPlan?.name}</Text>
        <Text>{generalUtils.formatPrice(price, currency)}</Text>
      </Flex>
      <Flex
        justifyContent='space-between'
        color='error'
        marginBottom='0.75rem'
        paddingBottom='0.75rem'
        borderBottom='1px solid rgba(0, 0, 0, 0.1)'
      >
        <Text>The Special Discount (-{(100 - (finalPrice / price) * 100).toFixed(0)}%)</Text>
        <Text>-{generalUtils.formatPrice(price - finalPrice, currency)}</Text>
      </Flex>
      <Flex justifyContent='space-between' color='black90'>
        <Text>Shipping</Text>
        <Text>{generalUtils.formatPrice(15, currency)}</Text>
      </Flex>
      <Flex justifyContent='space-between' color='error'>
        <Text>Discount (-100%)</Text>
        <Text>-{generalUtils.formatPrice(15, currency)}</Text>
      </Flex>
      <Flex
        justifyContent='space-between'
        paddingTop='0.75rem'
        borderTop='1px solid rgba(0, 0, 0, 0.1)'
        marginTop='0.75rem'
      >
        <Text fontFamily='heading' fontSize='24px'>
          Total:
        </Text>
        <Text fontFamily='heading' fontSize='24px'>
          {generalUtils.formatPrice(finalPrice, currency)}
        </Text>
      </Flex>
    </Flex>
  );
});

OrderSummary.displayName = 'OrderSummary';
