import { Box, CloseButton, Flex, Text } from '@chakra-ui/react';
import { OrderInterface, PaymentElement, StripeWrapper } from '@shared/payments';
import Image from 'next/image';
import { useEffect } from 'react';

import CheckCircleSVG from '~/assets/icons/checkcircle.svg';
import PaymentMethodsImage from '~/assets/images/payment_methods.png';
import { config } from '~/config';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useCheckout } from '~/hooks/useCheckout';
import { OrderSummary } from '~/layouts/OrderSummary';
import { colors } from '~/theme';
import { AppRoute } from '~/types';

export const CheckoutForm = () => {
  const { id, selectedPlan } = useAppLead();
  const { createOrder, trackPurchase, createdOrderNumber, finalPrice } = useCheckout();
  const { pushNextRoute, back } = useAppRouter(AppRoute.ORDER);
  const isOrder = ['4', '5', '6'].includes(selectedPlan?.id || '');

  const handlePaymentSuccess = (order: OrderInterface) => {
    trackPurchase(String(selectedPlan?.id), { method: order?.method });
  };

  useEffect(() => {
    if (createdOrderNumber) {
      pushNextRoute({ orderNumber: createdOrderNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdOrderNumber]);

  return (
    <Box width='100%'>
      <Flex justifyContent='right' marginBottom='1.5rem'>
        <CloseButton onClick={back} color='black' border='none' />
      </Flex>
      <Flex alignItems='center' marginBottom='0.75rem'>
        <CheckCircleSVG fill={colors.primary} stroke={colors.primary} />
        <Box flex={1} height='1px' bg='primary' />
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='primary' borderRadius='2rem' />
        <Box flex={1} height='1px' bg='black10' />
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='black10' borderRadius='2rem' />
      </Flex>
      <Flex justifyContent='space-between' marginBottom='1.5rem'>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          SHIPPING
        </Text>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          PAYMENT
        </Text>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          RECEIPT
        </Text>
      </Flex>
      <Text textAlign='center' fontSize={{ base: '24px', md: '32px' }} fontFamily='heading' marginBottom='1.5rem'>
        Submit a secure payment method
      </Text>
      <OrderSummary marginBottom='2rem' />
      <Box marginBottom='1.5rem'>
        <StripeWrapper apiKey={config.STRIPE.API_KEY}>
          <PaymentElement.Paypal
            createOrder={createOrder}
            forcePPRerender={[id]}
            onSuccess={handlePaymentSuccess}
            options={{ isSubscription: false }}
          />
          <Box marginTop='0.5rem'>
            <PaymentElement.PaymentButtons
              createOrder={createOrder}
              onSuccess={handlePaymentSuccess}
              options={{
                currency: 'USD',
                amount: finalPrice,
                title: selectedPlan ? `${selectedPlan.title}` : undefined,
              }}
            />
          </Box>
          <Flex alignItems='center' gap='1rem' marginY='0.75rem'>
            <Box flex={1} height='1px' bg='rgba(0, 0, 0, 0.1)' />
            <Text fontSize='12px' fontWeight={400} textAlign='center'>
              OR CREDIT CARD
            </Text>
            <Box flex={1} height='1px' bg='rgba(0, 0, 0, 0.1)' />
          </Flex>
          <PaymentElement.StripeCustomForm createOrder={createOrder} onSuccess={handlePaymentSuccess} />
        </StripeWrapper>
      </Box>
      <Text fontSize='12px' textAlign='center' marginBottom='0.5rem'>
        GUARANTEED <b>SAFE</b> CHECKOUT
      </Text>
      <Flex justifyContent='center' marginBottom='2rem'>
        <Image src={PaymentMethodsImage} width={342} height={17} alt='' />
      </Flex>
      <Flex flexDirection='column'>
        <Flex marginBottom='0.75rem' gap='0.5rem'>
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_180_2859)'>
              <path
                d='M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM12 17C10.9 17 10 16.1 10 15C10 13.9 10.9 13 12 13C13.1 13 14 13.9 14 15C14 16.1 13.1 17 12 17ZM15.1 8H8.9V6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8Z'
                fill='black'
                fillOpacity='0.64'
              />
            </g>
            <defs>
              <clipPath id='clip0_180_2859'>
                <rect width='20' height='20' fill='white' />
              </clipPath>
            </defs>
          </svg>
          <Text fontSize='13px' opacity='0.64' color='black'>
            Follow the instructions on the screen to complete your purchase securely.
          </Text>
        </Flex>
        <Flex marginBottom='0.75rem' gap='0.5rem'>
          <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_180_2873)'>
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13.41 18.09V20H10.74V18.07C9.03 17.71 7.58 16.61 7.47 14.67H9.43C9.53 15.72 10.25 16.54 12.08 16.54C14.04 16.54 14.48 15.56 14.48 14.95C14.48 14.12 14.04 13.34 11.81 12.81C9.33 12.21 7.63 11.19 7.63 9.14C7.63 7.42 9.02 6.3 10.74 5.93V4H13.41V5.95C15.27 6.4 16.2 7.81 16.26 9.34H14.3C14.25 8.23 13.66 7.47 12.08 7.47C10.58 7.47 9.68 8.15 9.68 9.11C9.68 9.95 10.33 10.5 12.35 11.02C14.37 11.54 16.53 12.41 16.53 14.93C16.52 16.76 15.15 17.76 13.41 18.09Z'
                fill='black'
                fillOpacity='0.64'
              />
            </g>
            <defs>
              <clipPath id='clip0_180_2873'>
                <rect width='24' height='24' fill='white' />
              </clipPath>
            </defs>
          </svg>
          <Text fontSize='13px' opacity='0.64' color='black'>
            Payments are charged in US
          </Text>
        </Flex>
        <Flex gap='0.75rem'>
          <Flex>
            <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g clipPath='url(#clip0_180_2866)'>
                <path
                  d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z'
                  fill='black'
                  fillOpacity='0.64'
                />
              </g>
              <defs>
                <clipPath id='clip0_180_2866'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </Flex>
          <Text fontSize='13px' opacity='0.64' color='black'>
            {isOrder
              ? `${finalPrice} USD will be billed now. No hidden fees.`
              : `${finalPrice} USD will be billed now. After the first ${
                  selectedPlan?.plan?.interval && selectedPlan?.plan?.interval > 1
                    ? `${selectedPlan?.plan?.interval} months`
                    : 'month'
                }, ${finalPrice} USD will be billed automatically. No hidden fees. Cancel anytime.`}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
