import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

import CheckCircleSVG from '~/assets/icons/checkcircle.svg';
import { colors } from '~/theme';

type ProgressDotProps = {
  items: string[];
  activeIndex: number;
};

export const ProgressDot = ({ items, activeIndex }: ProgressDotProps) => {
  return (
    <Box>
      <Flex alignItems='center' marginBottom='0.75rem'>
        {items.map((item, index) => {
          const activeDot = index <= activeIndex;
          const activeLine = index < activeIndex;
          const isLast = index === items.length - 1;

          return <Dot key={item} activeDot={activeDot} activeLine={activeLine} isLast={isLast} />;
        })}
      </Flex>
      <Flex justifyContent='space-between' marginBottom='1.5rem'>
        {items.map((item) => {
          return <Label key={item}>{item}</Label>;
        })}
      </Flex>
    </Box>
  );
};

type DotProps = {
  isLast?: boolean;
  activeDot?: boolean;
  activeLine?: boolean;
};

const Dot = ({ isLast, activeDot, activeLine }: DotProps) => {
  return (
    <>
      {activeDot ? (
        <CheckCircleSVG fill={colors.primary} stroke={colors.primary} />
      ) : (
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='black10' borderRadius='2rem' />
      )}
      {isLast ? null : <Box flex={1} height='1px' bg={activeLine ? 'primary' : 'black10'} />}
    </>
  );
};

const Label = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontSize='12px' fontWeight={600} color='black60'>
      {children}
    </Text>
  );
};
