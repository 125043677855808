import { CreatePayment, Order } from '@menesko/models-harmonia';
import { useMutation, UseMutationOptions } from 'react-query';

import { APIClient, FunctionName, getAPIUrl } from '~/firebase-init';

type Request = CreatePayment;
interface Response {
  id: string;
  orderNumber: Order['orderNumber'];
}

export const usePaymentMutation = (
  options?: Omit<UseMutationOptions<Response, unknown, Request, unknown>, 'mutationKey'>,
) => {
  return useMutation<Response, unknown, Request>(FunctionName.PAYMENT, {
    mutationFn: (data) => APIClient.post<Request, Response>(getAPIUrl(FunctionName.PAYMENT), data),
    ...options,
  });
};
